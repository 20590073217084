.sign-page .sign-container .apple {
  padding-top: 32px;
}

.sign-page .sign-container .apple img {
  width: 150px;
  height: 50px;
}

.sign-page .sign-container .apple span {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 30px;
  color: #aa9c84;
}
