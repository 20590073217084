@import '../../../common.scss';

@media only screen and (max-width: 300px) {
	.sign-button {
		width: 100% !important;
		height: auto !important;
	}
}

.sign-button {
	background: white;
	width: 218px;
	height: 43px;
	border-radius: 3px;
	filter: drop-shadow(0px 2px 1px #ede9e3);

	.icon {
		position: absolute;

		img {
			position: absolute;
			left: 18px;
			width: 22px;
		}
	}

	.name {
		padding-left: 51px;
		padding-top: 10px;
		padding-bottom: 10px;
		span {
			font-family: 'SF Compact Display Regular' !important;
			font-size: 15px !important;
			line-height: 18.5px !important;
			color: #757575 !important;
		}
	}

	&:hover {
		span {
			color: #959595 !important;
		}
		cursor: pointer;
	}

	&:active {
		span {
			color: #525252 !important;
		}
		cursor: pointer;
	}
}