body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.btnframe {
  border: 1px solid blue;
  border-radius: 3px;
  font-family: 'SF Pro Display Medium';
  font-size: 14px;
  line-height: 24px;
  padding-top: 6px;
  padding-bottom: 5px;
  background: white; }

.btnnoframe {
  border: 0;
  background: none;
  font-family: 'SF Pro Display Medium';
  font-size: 14px;
  line-height: 24px;
  padding-top: 6px;
  padding-bottom: 5px; }

.btn-green {
  border-color: #038930 !important;
  color: #038930; }
  .btn-green:hover {
    color: #0ea742;
    border-color: #0ea742 !important; }
  .btn-green:active {
    color: #037028;
    border-color: #037028 !important; }

.btn-black {
  border-color: #111111 !important;
  color: #111111; }
  .btn-black:hover {
    color: #757575;
    border-color: #757575 !important; }
  .btn-black:active {
    color: #000000;
    border-color: #000000 !important; }

.btn-gray {
  border-color: #757575 !important;
  color: #757575; }
  .btn-gray:hover {
    color: #989898;
    border-color: #989898 !important; }
  .btn-gray:active {
    color: #555555;
    border-color: #555555 !important; }

button {
  cursor: pointer; }

button:focus {
  outline: none !important; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.footer-back {
  border: 1px solid #d6d6d6;
  border-width: 1px 0 0 0;
  background: #ede5d7;
  padding: 24px 0 46px 0; }
  .footer-back .copyright {
    float: left;
    width: 50%; }
  .footer-back .footer-action {
    width: 100%; }
  .footer-back span {
    font-family: 'SF Pro Text Regular';
    font-size: 14px;
    line-height: 30px;
    color: #897655; }
    .footer-back span a {
      color: #897655;
      cursor: pointer; }

@media only screen and (max-width: 1032px) {
  .footer-back .copyright {
    width: 100%;
    margin-bottom: 10px; } }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.btnframe {
  border: 1px solid #00f;
  border-radius: 3px;
  background: #fff;
}

.btnframe,
.btnnoframe {
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 24px;
  padding-top: 6px;
  padding-bottom: 5px;
}

.btnnoframe {
  border: 0;
  background: none;
}

.btn-green {
  border-color: #038930 !important;
  color: #038930;
}

.btn-green:hover {
  color: #0ea742;
  border-color: #0ea742 !important;
}

.btn-green:active {
  color: #037028;
  border-color: #037028 !important;
}

.btn-black {
  border-color: #111 !important;
  color: #111;
}

.btn-black:hover {
  color: #757575;
  border-color: #757575 !important;
}

.btn-black:active {
  color: #000;
  border-color: #000 !important;
}

.btn-gray {
  border-color: #757575 !important;
  color: #757575;
}

.btn-gray:hover {
  color: #989898;
  border-color: #989898 !important;
}

.btn-gray:active {
  color: #555;
  border-color: #555 !important;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none !important;
}

.footer-back {
  border: solid #d6d6d6;
  border-width: 1px 0 0;
  background: #ede5d7;
  padding: 24px 0 46px;
}

.footer-back .copyright {
  float: left;
  width: 50%;
}

.footer-back .footer-action {
  width: 100%;
}

.footer-back span {
  font-family: SF Pro Text Regular;
  font-size: 14px;
  line-height: 30px;
  color: #897655;
}

.footer-back span a {
  color: #897655;
  cursor: pointer;
}

@media only screen and (max-width: 1032px) {
  .footer-back .copyright {
    width: 100%;
    margin-bottom: 10px;
  }
}

.main-container {
  width: 100%;
}

.main-back {
  background: #fbf7f0;
}

.about-section {
  background: #f3ecdf;
}

.home-screen .header {
  padding-top: 14px;
  padding-bottom: 32px;
  height: 37px;
}

.home-screen .header .header-logo {
  width: 40px;
  float: left;
}

.home-screen .header .header-logo img.logo {
  height: 22px;
  margin-top: 7px;
}

.home-screen .header .header-action {
  width: 100%;
}

.cover-section {
  position: relative;
  padding-bottom: 20px;
  display: flex;
}

.cover-section .subscription {
  width: 452px;
  float: left;
  padding-top: 42px;
}

.cover-section .subscription .subscription-title {
  font-family: Saol Display Bold;
  font-size: 42px;
  line-height: 54px;
  letter-spacing: -0.01em;
}

.cover-section .subscription .subscription-content {
  padding-left: 64px;
  padding-right: 10px;
  padding-top: 42px;
}

.cover-section .subscription .subscription-content p {
  font-family: SF Pro Display Regular;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.cover-section .subscription .subscription-content p span {
  font-family: SF Pro Display Bold;
}

.cover-section .subscription .btn-try button {
  height: 50px;
  width: 190px;
  margin-top: 28px;
  background: #ff4500;
  font-family: SF Pro Display Bold;
  font-size: 17px;
  line-height: 30px;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0;
}

.cover-section .subscription .btn-try button:hover {
  background: #fc5517;
}

.cover-section .home-logo {
  padding-left: 120px;
  padding-top: 38px;
}

.cover-section .home-logo img.hero-img {
  width: 397px;
  height: 445px;
}

.cover-section .vertical-line {
  position: absolute;
  left: 33px;
  top: 175px;
  width: 1px;
  height: 510px;
  background: #000;
}

.about-section {
  padding: 70px 0 90px;
}

.about-section .about-box {
  margin-top: 70px;
}

.about-section .about-box span.title {
  font-family: SF Compact Text SemiBold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #ff4500;
}

.about-section .about-box span.desc {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 30px;
  color: #aa9c84;
}

.about-section .about-box p {
  font-family: Calluna Regular;
  font-size: 18px;
  line-height: 30px;
  color: #111;
}

.about-section .about-box .btn-app-store {
  width: 150px;
  height: 50px;
  cursor: pointer;
  margin-top: 15px;
}

.about-section .special-box .special-content {
  border: 1px solid #e7e3db;
  border-radius: 5px;
  background: #fbf7f0;
  padding-top: 14px;
  padding-bottom: 5px;
}

.about-section .special-box .special-content div.line {
  width: 100%;
  background: #d6d6d6;
  height: 1px;
}

.about-section .special-box .special-content .special-item {
  padding: 27px 0 3px;
}

.about-section .special-box .special-content .special-item .content {
  padding-left: 88px;
  padding-right: 20px;
}

.about-section .special-box .special-content .special-item .content span {
  font-family: SF Pro Display Bold;
  font-size: 18px;
  color: #897655;
}

.about-section .special-box .special-content .special-item .content p {
  font-family: SF Pro Display Regular;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.about-section .special-box .special-content .special-item .sp-icon {
  position: absolute;
}

.about-section .special-box .special-content .special-item .sp-icon svg {
  position: absolute;
  top: 5px;
  left: 30px;
}

@media only screen and (max-width: 360px) {
  .cover-section {
    padding-bottom: 40px;
  }

  .cover-section .subscription {
    width: 100%;
    padding-top: 24px;
  }

  .cover-section .subscription .subscription-title {
    padding-left: 9px;
    padding-right: 15px;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  .cover-section .subscription .subscription-content {
    padding-left: 9px;
    padding-top: 20px;
    padding-right: 15px;
  }

  .cover-section .subscription .subscription-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .cover-section .home-logo,
  .cover-section .vertical-line {
    display: none;
  }

  .about-section {
    padding: 45px 0;
  }

  .about-section .about-box {
    width: 100%;
    text-align: left;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .about-section .about-box .content {
    padding: 0 30px;
  }

  .about-section .special-box {
    text-align: center;
    width: 100%;
  }

  .about-section .special-box .content {
    padding: 20px 22px;
  }

  .about-section .special-box .content .special-item {
    padding-top: 10px;
  }

  .about-section .special-box .content .special-item .sp-icon {
    position: relative;
  }

  .about-section .special-box .content .special-item .sp-icon svg {
    position: relative;
    left: 0;
  }

  .about-section .special-box .content .special-item .content {
    padding: 10px 20px;
  }

  .about-section .special-box .content .special-item .content p {
    margin-top: 9px;
    text-align: center;
  }
}

@media only screen and (max-width: 1032px) and(min-width: 360px) {
  .cover-section {
    padding: 0 10px 40px;
  }

  .cover-section .subscription {
    width: 100%;
    padding-top: 24px;
  }

  .cover-section .subscription .subscription-title {
    padding-left: 9px;
    padding-right: 15px;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  .cover-section .subscription .subscription-content {
    padding-left: 9px;
    padding-top: 20px;
    padding-right: 15px;
  }

  .cover-section .subscription .subscription-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .cover-section .home-logo,
  .cover-section .vertical-line {
    display: none;
  }

  .about-section {
    padding: 40px 0 45px;
  }

  .about-section .about-box {
    width: 100%;
    text-align: left;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .about-section .about-box .content {
    padding: 0 50px 0 32px;
  }

  .about-section .special-box {
    text-align: center;
    width: 100%;
  }

  .about-section .special-box .content {
    padding: 40px 42px;
  }

  .about-section .special-box .content .special-item {
    padding-top: 10px;
  }

  .about-section .special-box .content .special-item .sp-icon {
    position: relative;
  }

  .about-section .special-box .content .special-item .sp-icon svg {
    position: relative;
    left: 0;
  }

  .about-section .special-box .content .special-item .content {
    padding: 10px 20px;
  }

  .about-section .special-box .content .special-item .content p {
    margin-top: 9px;
    text-align: center;
  }
}

@media only screen and (min-width: 1032px) {
  .about-section {
    padding: 80px 0 90px;
  }

  .about-section .about-box {
    float: left;
    width: 50%;
  }

  .about-section .about-box .content {
    padding: 0 65px 0 64px;
  }

  .about-section .special-box {
    width: 100%;
    display: table-cell;
  }

  .about-section .special-box .content {
    padding: 8px 41px 0 15px;
  }
}

.phone-section .img-section img {
  margin-bottom: -4px;
}

@media only screen and (max-width: 300px) {
  .sign-button {
    width: 100% !important;
    height: auto !important;
  }
}

.sign-button {
  background: #fff;
  width: 218px;
  height: 43px;
  border-radius: 3px;
  -webkit-filter: drop-shadow(0 2px 1px #ede9e3);
  filter: drop-shadow(0 2px 1px #ede9e3);
}

.sign-button .icon {
  position: absolute;
}

.sign-button .icon img {
  position: absolute;
  left: 18px;
  width: 22px;
}

.sign-button .name {
  padding-left: 51px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sign-button .name span {
  font-family: SF Compact Display Regular !important;
  font-size: 15px !important;
  line-height: 18.5px !important;
  color: #757575 !important;
}

.sign-button:hover {
  cursor: pointer;
}

.sign-button:hover span {
  color: #959595 !important;
}

.sign-button:active {
  cursor: pointer;
}

.sign-button:active span {
  color: #525252 !important;
}

body {
  background: #f3ecdf;
}

.sign-page .sign-container .logo {
  padding-top: 57px;
  padding-bottom: 32px;
  width: 100%;
}

.sign-page .sign-container .logo img {
  width: 49px;
  height: 64px;
}

.sign-page .sign-container .main-block {
  border: 1px solid #e7e3db;
  border-radius: 4px;
  background: #fbf7f0;
  padding: 31px 40px 40px;
}

.sign-page .sign-container .main-block .title {
  font-family: SF Compact Text Semibold;
  font-size: 14px;
  letter-spacing: 0.025em;
  color: #ff4500;
  text-transform: uppercase;
}

.sign-page .sign-container .main-block p {
  font-family: Calluna Regular;
  font-size: 18px;
  line-height: 30px;
  color: #111;
  margin-top: 11px;
}

.sign-page .sign-container .main-block p span {
  font-family: Calluna Bold;
}

.sign-page .sign-container .main-block .signin-action span {
  font-family: SF Pro Display Regular;
  font-size: 14px;
  line-height: 24px;
  color: #897655;
}

.sign-page .sign-container .main-block .signin-action span a {
  color: #038930;
  text-decoration: none;
}

.sign-page .sign-container .main-block .signin-form input {
  width: 219px;
  height: 48px;
  background: none;
  border: solid #6f6d6a;
  border-width: 0 0 1px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  text-align: center;
  font-family: SF Compact Display Regular;
}

.sign-page
  .sign-container
  .main-block
  .signin-form
  input::-webkit-input-placeholder {
  font-family: SF Compact Display Regular;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #757575;
}

.sign-page .sign-container .main-block .signin-form input::-webkit-input-placeholder {
  font-family: SF Compact Display Regular;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #757575;
}

.sign-page .sign-container .main-block .signin-form input::placeholder {
  font-family: SF Compact Display Regular;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #757575;
}

.sign-page .sign-container .main-block .signin-form input:focus {
  outline: none !important;
}

.sign-page .sign-container .main-block .signin-form input.invalid {
  border-color: red !important;
}

.sign-page
  .sign-container
  .main-block
  .signin-form
  input.invalid::-webkit-input-placeholder {
  color: red;
}

.sign-page .sign-container .main-block .signin-form input.invalid::-webkit-input-placeholder {
  color: red;
}

.sign-page .sign-container .main-block .signin-form input.invalid::placeholder {
  color: red;
}

.sign-page .sign-container .main-block .signin-form .form-action {
  font-family: SF Compact Display Regular;
  font-size: 15px;
  line-height: 18.5px;
  color: #fff;
  background: #3c863c;
  border: 0;
  border-radius: 3px;
  width: 150px;
  text-align: center;
  padding: 14px 0;
}

.sign-page .sign-container .apple {
  padding-top: 32px;
}

.sign-page .sign-container .apple img {
  width: 150px;
  height: 50px;
}

.sign-page .sign-container .apple span {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 30px;
  color: #aa9c84;
}

.sign-page .sign-container .last-footer {
  padding-top: 10px;
}

.sign-page .sign-container .last-footer span {
  font-family: SF Pro Display Regular;
  font-size: 14px;
  line-height: 18.5px;
  letter-spacing: 0.01em;
  color: #aa9c84;
}

.sign-page .sign-container .last-footer span a {
  text-decoration: underline;
  color: #aa9c84;
}

.sign-page .sign-container .email-verify {
  color: #155724;
  background-color: #d4edda;
  border-radius: 0.25rem;
  border: 1px solid #c3e6cb;
  padding: 10px 20px;
}

.sign-page .sign-container .hidden {
  display: none;
}

.sign-page .sign-back {
  position: absolute;
  top: 17px;
  left: 50px;
}

.sign-page .sign-back img {
  width: 16px;
  height: 30px;
}

@media only screen and (max-width: 491px) {
  .sign-page .sign-container {
    padding: 0 23px;
  }

  .sign-page .sign-container .main-block {
    padding: 31px 17px 40px;
  }
}

@media only screen and (min-width: 491px) {
  .sign-page .sign-container {
    width: 442px;
  }
}

.header-back {
  width: 100%;
  height: 82px;
  border: solid #d6d6d6;
  border-width: 0 0 1px;
  background: #f3ecdf;
}

.header-back .logo {
  padding-top: 24px;
  float: left;
  width: 30px;
}

.header-back .logo img {
  width: 27px;
  height: 35px;
}

.header-back .header-action {
  padding-top: 23px;
  width: 100%;
}

.header-back .header-action .actions {
  float: right;
  margin-left: 36px;
}

.header-back .header-action .actions svg {
  margin-top: 4px;
  float: left;
}

@keyframes search-ani-show {
  0% {
    width: 0;
  }

  to {
    width: 220px;
  }
}

.header-back .header-action .actions input {
  width: 220px;
  height: 37px;
  border: 0;
  background: #fbf7f0;
  margin-left: 11px;
  padding: 0 12px;
  animation-name: search-ani-show;
  animation-duration: 1s;
}

.header-back .header-action .actions input::-webkit-input-placeholder {
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 36px;
}

.header-back .header-action .actions input::placeholder {
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 36px;
}

.header-back .header-action .actions .hide {
  display: none;
}

.header-back .header-action .actions .sub-menu {
  position: absolute;
}

@keyframes user-menu-ani-show {
  0% {
    height: 0;
  }

  to {
    height: 144px;
  }
}

.header-back .header-action .actions .sub-menu .user-menu {
  position: absolute;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  background: #fff;
  list-style: none;
  top: 40px;
  right: -32px;
  padding: 20px 0;
  text-align: left;
  width: 232px;
  margin: 0;
  animation-name: user-menu-ani-show;
  animation-duration: 0.5s;
}

.header-back .header-action .actions .sub-menu .user-menu li {
  padding: 0 20px;
  cursor: pointer;
}

.header-back .header-action .actions .sub-menu .user-menu li a,
.header-back .header-action .actions .sub-menu .user-menu li div {
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 36px;
  color: #757575;
  text-decoration: none;
  padding: 0;
}

.header-back .header-action .actions .sub-menu .user-menu li:hover {
  background: #e4e4e4;
}

@media only screen and (max-width: 1052px) {
  .page-container {
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 1052px) {
  .page-container {
    width: 700px;
  }
}

.tertius-main-page {
  width: 100%;
  background: #fbf7f0;
}

.tertius-main-page .page-container {
  padding-top: 66px;
  padding-bottom: 66px;
}

.page-search {
  min-height: 650px;
}

.page-search .content span {
  font-family: SF Pro Display Medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.075em;
  color: #757575;
  text-transform: uppercase;
}

.page-search .content input {
  margin: 20px 0;
  border: solid #d6d6d6;
  border-width: 0 0 1px;
  background: none;
  font-family: SF Pro Display Regular;
  font-size: 40px;
  width: -webkit-fill-available;
  width: 100%;
}

.page-search .content input:focus {
  outline: none !important;
}

.page-filter {
  min-height: 650px;
}

.page-filter .content .tags {
  width: 100%;
  padding: 40px 0 28px;
  display: inline-block;
}

.page-filter .content .tags .tag {
  float: left;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  padding: 6px 18px 5px 19px;
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  margin-right: 9px;
  margin-bottom: 9px;
  cursor: pointer;
}

.page-filter .content .tags .tag:hover {
  background: #f1f1f1;
}

.subscribe {
  margin-top: 14px;
}

.subscribe .subscribe-content .logo img {
  width: 32px;
  height: 42px;
}

.subscribe .subscribe-content .title {
  font-family: SF Pro Display Bold;
  font-size: 22px;
  line-height: 36px;
}

.subscribe .subscribe-content .desc {
  font-family: SF Pro Display Regular;
  font-size: 18px;
  line-height: 24px;
  color: #757575;
}

.subscribe .subscribe-content .plan {
  text-align: left;
}

.subscribe .subscribe-content .plan span.title {
  font-family: SF Pro Display Regular;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}

.subscribe .subscribe-content .plan .content {
  margin-top: 16px;
  margin-bottom: 20px;
}

.subscribe .subscribe-content .plan .content .container {
  display: inline;
  position: relative;
  padding-left: 35px;
  font-family: SF Pro Display Regular;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 30px;
}

.subscribe .subscribe-content .plan .content .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.subscribe .subscribe-content .plan .content .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border: 1px solid grey;
  border-radius: 50%;
}

.subscribe
  .subscribe-content
  .plan
  .content
  .container:hover
  input
  ~ .checkmark {
  background-color: #ccc;
}

.subscribe
  .subscribe-content
  .plan
  .content
  .container
  input:checked
  ~ .checkmark {
  background-color: #fff;
}

.subscribe .subscribe-content .plan .content .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.subscribe
  .subscribe-content
  .plan
  .content
  .container
  input:checked
  ~ .checkmark:after {
  display: block;
}

.subscribe .subscribe-content .plan .content .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff4500;
}

.subscribe .subscribe-content .payment {
  text-align: left;
}

.subscribe .subscribe-content .payment span.title {
  font-family: SF Pro Display Regular;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}

.subscribe .subscribe-content .payment .content {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  padding: 22px 16px;
}

.subscribe .subscribe-content .payment .content .payment-title {
  border: solid #ff4500;
  border-width: 0 0 3px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.subscribe .subscribe-content .payment .content .payment-title span {
  font-family: SF Pro Display Regular;
  font-size: 15px;
  line-height: 24px;
  color: #ff4500;
}

.subscribe .subscribe-content .payment .content .membership-desc p {
  font-family: SF Pro Display Regular;
  font-size: 14px;
  line-height: 22px;
  color: #757575;
}

.subscribe .subscribe-content .payment .content .membership-desc p a {
  color: #757575;
}

.subscribe .subscribe-content .payment .content .membership-desc p a:hover {
  color: #0099e5;
}

.subscribe .subscribe-content .payment .content .card-info {
  font-family: SF Pro Display Regular;
  font-size: 14px;
  line-height: 20px;
}

.subscribe .subscribe-content .payment .content .card-info span {
  color: #757575;
}

.subscribe .subscribe-content .payment .content .card-info input {
  margin-top: 5px;
  width: 100%;
  border-width: 0 0 1px;
  border-color: #d9d9d9;
}

.subscribe .subscribe-content .payment .content .card-info input:focus {
  outline: none !important;
}

.subscribe .subscribe-content .payment .content .card-info .credit-card-number {
  position: relative;
  width: 100%;
}

.subscribe
  .subscribe-content
  .payment
  .content
  .card-info
  .credit-card-number
  input {
  width: calc(100% - 25px);
  padding-left: 30px;
  font-size: 12px;
}

.subscribe
  .subscribe-content
  .payment
  .content
  .card-info
  .credit-card-number
  .card-icon {
  position: absolute;
  top: 22px;
  left: 5px;
  font-size: 15px;
}

.subscribe
  .subscribe-content
  .payment
  .content
  .card-info
  .credit-card-number
  .card-icon
  i {
  color: #757575;
}

.subscribe .subscribe-content .payment .content .card-info .exp-sec {
  margin-top: 10px;
  width: 100%;
  display: flex;
}

.subscribe
  .subscribe-content
  .payment
  .content
  .card-info
  .exp-sec
  .expiration {
  float: left;
  width: 45%;
  margin-right: 5%;
}

.subscribe
  .subscribe-content
  .payment
  .content
  .card-info
  .exp-sec
  .expiration
  input {
  font-size: 12px;
}

.subscribe
  .subscribe-content
  .payment
  .content
  .card-info
  .exp-sec
  .security-code {
  width: 45%;
  margin-left: 5%;
}

.subscribe
  .subscribe-content
  .payment
  .content
  .card-info
  .exp-sec
  .security-code
  input {
  font-size: 12px;
}

.subscribe .subscribe-content .payment .content .action button {
  width: 235px;
  height: 37px;
  border: 1px solid #070707;
  border-radius: 3px;
  background: #292929;
  font-family: SF Pro Display Regular;
  font-size: 18px;
  color: #f8f8f8;
}

.subscribe .subscribe-content .payment .content .error-message {
  padding: 0 0 10px;
}

.subscribe .subscribe-content .payment .content .error-message span {
  color: #ff4500;
  font-family: SF Pro Display Regular;
  font-size: 14px;
}

.subscribe .subscribe-content .payment .content .loading-container {
  padding-bottom: 10px;
  padding-top: 0;
}

@media only screen and (max-width: 496px) {
  .subscribe-content {
    padding-left: 23px;
    padding-right: 23px;
    width: calc(100% - 46px);
  }
}

@media only screen and (min-width: 496px) {
  .subscribe-content {
    width: 448px;
    padding-left: 23px;
    padding-right: 23px;
  }
}

.post-item .post-item-content {
  width: 100%;
}

.post-item .post-item-content .citation {
  padding-left: 24px;
  padding-bottom: 2px;
  min-height: 25px;
}

.post-item .post-item-content .citation .icon {
  position: absolute;
}

.post-item .post-item-content .citation .icon svg {
  position: absolute;
  top: 2px;
  left: -25px;
}

.post-item .post-item-content .citation span {
  font-family: SF Pro Display Regular;
  font-size: 16px;
  line-height: 24.01px;
  letter-spacing: 0.025em;
  color: #6d6d6d;
}

.post-item .post-item-content span.post-title {
  font-family: SF Pro Display Bold;
  font-size: 42px;
  letter-spacing: -0.01em;
  cursor: pointer;
  color: #111;
}

.post-item .post-item-content span.post-title:hover {
  color: #038930;
}

.post-item .post-item-content span.post-title:active {
  color: #000;
}

.post-item .post-item-content p.post-content {
  font-family: Calluna Regular;
  font-size: 21px;
  line-height: 31px;
  height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.post-item .post-item-content p.post-content :first-child {
  margin-top: 0;
}

.post-item .post-item-content p.post-content b {
  font-family: Calluna Bold !important;
}

.post-item .post-item-content p.post-content b i {
  font-family: Calluna Bold Italic !important;
}

.post-item .post-item-content p.post-content i {
  font-family: Calluna Italic !important;
}

.post-item .post-item-content p.post-content i b {
  font-family: Calluna Bold Italic !important;
}

.post-item .post-line {
  width: 100%;
  height: 1px;
  background: #d6d6d6;
}

.post-list {
  min-height: 650px;
}

.post-list .postlist .action {
  padding: 33px 0 0;
}

.post-list .postlist .filter-header span.fh-title {
  text-transform: uppercase;
  font-family: SF Pro Display Medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.075em;
  color: #757575;
}

.post-list .postlist .filter-header .tag {
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  padding: 6px 18px 5px 19px;
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  display: inline-block;
}

.post-list .postlist .filter-header .filter-line {
  width: 100%;
  height: 1px;
  background: #d6d6d6;
}

.post-list .postlist .filter-header input {
  font-family: SF Pro Display Regular;
  font-size: 36px;
  line-height: 24px;
  color: #111;
  border: 0;
  padding: 0 7px;
  width: 100%;
  background: none;
}

.post-list .postlist .filter-header input:focus {
  outline: none !important;
}

.post-list .postlist .filter-header span.filter-content {
  font-family: SF Pro Display Regular;
  font-size: 36px;
  line-height: 24px;
  color: #111;
}

.post {
  min-height: 650px;
}

.post .citations {
  width: 100%;
}

.post .citations .citation {
  padding-left: 24px;
  padding-bottom: 2px;
  cursor: pointer;
  min-height: 25px;
}

.post .citations .citation .icon {
  position: absolute;
}

.post .citations .citation .icon svg {
  position: absolute;
  top: 2px;
  left: -25px;
}

.post .citations .citation span {
  font-family: SF Pro Display Regular;
  font-size: 16px;
  line-height: 24.01px;
  letter-spacing: 0.025em;
  color: #6d6d6d;
}

.post .post-setting {
  float: right;
  width: 30px;
}

.post .post-setting .sub-menu {
  position: absolute;
}

@keyframes post-setting-menu-ani-show {
  0% {
    height: 0;
  }

  to {
    height: 216px;
  }
}

.post .post-setting .sub-menu .setting-menu {
  position: absolute;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  background: #fff;
  list-style: none;
  top: 15px;
  right: -32px;
  padding: 20px 0;
  text-align: left;
  width: 232px;
  margin: 0;
  animation-name: post-setting-menu-ani-show;
  animation-duration: 0.5s;
}

.post .post-setting .sub-menu .setting-menu li {
  padding: 0 20px;
  cursor: pointer;
}

.post .post-setting .sub-menu .setting-menu li a,
.post .post-setting .sub-menu .setting-menu li div {
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 36px;
  color: #757575;
  text-decoration: none;
  padding: 0;
}

.post .post-setting .sub-menu .setting-menu li .trash {
  color: #ff3b30;
}

.post .post-setting .sub-menu .setting-menu li:hover {
  background: #e4e4e4;
}

.post .content span.post-title {
  font-family: SF Pro Display Bold;
  font-size: 42px;
  letter-spacing: -0.01em;
}

.post .content .comment_p {
  white-space: pre-line;
}

.post .content p.post-content {
  font-family: Calluna Regular;
  font-size: 21px;
  line-height: 31px;
  white-space: pre-line;
  margin: 0;
}

.post .content p.post-content p {
  margin: 0 !important;
}

.post .content p.post-content b {
  font-family: Calluna Bold !important;
}

.post .content p.post-content b i {
  font-family: Calluna Bold Italic !important;
}

.post .content p.post-content i {
  font-family: Calluna Italic !important;
}

.post .content p.post-content i b {
  font-family: Calluna Bold Italic !important;
}

.post .content .post-line {
  width: 100%;
  height: 1px;
  background: #d6d6d6;
}

.post .content .tag-list {
  width: 100%;
  padding: 40px 0 28px;
  display: inline-block;
}

.post .content .tag-list .tag {
  float: left;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  padding: 6px 18px 5px 19px;
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  margin-right: 9px;
  margin-bottom: 9px;
  cursor: pointer;
}

.post .content .comment .comment-head {
  padding-left: 26px;
  position: absolute;
}

.post .content .comment .comment-head svg {
  position: absolute;
  top: 2px;
  left: 0;
}

.post .content .comment .comment-head span {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 24px;
  color: #111;
}

.post .content .comment p {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 31px;
}

.edit-title-excerpt {
  min-height: 650px;
}

.edit-title-excerpt .content .post-title {
  width: 100%;
}

.edit-title-excerpt .content .post-title textarea {
  width: -webkit-fill-available;
  width: 100%;
  resize: none;
  padding: 0;
  border: 0;
  background: none;
  font-family: SF Pro Display Bold;
  font-size: 42px;
  letter-spacing: -0.01em;
  color: #111;
  min-height: 50px !important;
}

.edit-title-excerpt .content .post-title textarea:focus {
  outline: none !important;
}

.edit-title-excerpt .content .post-excerpt {
  padding-top: 5px;
  width: 100%;
}

.edit-title-excerpt .content .post-excerpt p.excerpt-para {
  white-space: pre-line;
}

.edit-title-excerpt
  .content
  .post-excerpt
  p.excerpt-para
  .medium-editor-element {
  width: -webkit-fill-available !important;
  width: 100% !important;
  resize: none !important;
  padding: 0 !important;
  border: 0 !important;
  background: none !important;
  font-family: Calluna Regular !important;
  font-size: 21px !important;
  line-height: 31px !important;
  color: #111 !important;
  outline: none !important;
}

.edit-title-excerpt
  .content
  .post-excerpt
  p.excerpt-para
  .medium-editor-element:focus {
  outline: none !important;
}

.edit-title-excerpt
  .content
  .post-excerpt
  p.excerpt-para
  .medium-editor-element
  p {
  margin: 0;
}

.edit-title-excerpt
  .content
  .post-excerpt
  p.excerpt-para
  .medium-editor-element
  b {
  font-family: Calluna Bold !important;
}

.edit-title-excerpt
  .content
  .post-excerpt
  p.excerpt-para
  .medium-editor-element
  b
  i {
  font-family: Calluna Bold Italic !important;
}

.edit-title-excerpt
  .content
  .post-excerpt
  p.excerpt-para
  .medium-editor-element
  i {
  font-family: Calluna Italic !important;
}

.edit-title-excerpt
  .content
  .post-excerpt
  p.excerpt-para
  .medium-editor-element
  i
  b {
  font-family: Calluna Bold Italic !important;
}

.edit-title-excerpt .content .post-actions {
  position: relative;
}

.edit-title-excerpt .content .post-actions .buttons {
  width: 210px;
  position: absolute;
  top: -130px;
  right: -100px;
}

.edit-tags {
  min-height: 650px;
}

.edit-tags .content .tags {
  width: 100%;
  display: inline-block;
}

.edit-tags .content .tags .tag {
  float: left;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  padding: 6px 15px 5px 20px;
  margin-right: 10px;
  margin-bottom: 9px;
}

.edit-tags .content .tags .tag span {
  float: left;
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
}

.edit-tags .content .tags .tag .btn-close {
  float: left;
  padding-left: 3px;
  padding-top: 3px;
}

.edit-tags .content .tags input {
  border: 0;
  background: none;
  height: 37px;
  padding-left: 5px;
  padding-top: 0;
}

.edit-tags .content .tags input:placeholder {
  font-family: SF Pro Display Medium;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
}

.edit-tags .content .tags input:focus {
  outline: none !important;
}

.edit-tags .content .tags .input_tag {
  font-size: 14px !important;
  line-height: 24px !important;
  color: #757575 !important;
  font-family: SF Pro Display Medium !important;
}

.edit-tags .content .actions {
  padding-top: 30px;
}

.edit-comment {
  min-height: 650px;
}

.edit-comment .content textarea {
  width: -webkit-fill-available;
  width: 100%;
  resize: none;
  padding: 0;
  border: 0;
  background: none;
  min-height: 220px;
  max-height: 400px;
  overflow: scroll;
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 31px;
}

.edit-comment .content textarea::-webkit-scrollbar {
  width: 0;
}

.edit-comment .content textarea:focus {
  outline: none !important;
}

.edit-comment .content .actions {
  padding-top: 40px;
}

.edit-citation {
  padding-top: 40px !important;
  min-height: 650px;
}

.edit-citation .content .citation {
  width: 100%;
}

.edit-citation .content .citation input {
  padding: 12px 7px 0;
  border: solid #d6d6d6;
  border-width: 0 0 1px;
  font-family: SF Pro Display Regular;
  font-size: 16px;
  line-height: 46px;
  color: #111;
  background: none;
  width: -webkit-fill-available;
  width: 100%;
}

.edit-citation .content .citation input:focus {
  outline: none !important;
}

.edit-citation .content .actions {
  padding-top: 40px;
}

.fr-btn,
.fr-buttons,
.fr-toolbar {
  background: #000 !important;
}

.fr-btn {
  color: #fff !important;
}

.fr-popup {
  border-color: #000 !important;
}

.fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a {
  color: #000 !important;
}

.settings .title {
  padding-bottom: 35px;
  border: solid #d6d6d6;
  border-width: 0 0 1px;
}

.settings .title span {
  font-family: SF Pro Display Medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #757575;
}

.settings .content .section {
  width: 100%;
  border: solid #d6d6d6;
  border-width: 0 0 1px;
  padding: 39px 0 32px;
}

.settings .content .section .section-title span {
  font-family: SF Pro Display Bold;
  font-size: 18px;
  letter-spacing: -0.01em;
}

.settings .content .section p {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 31px;
}

.settings .content .section .email-row .email-label {
  float: left;
  padding-top: 5px;
  width: calc(100% - 150px);
}

.settings .content .section .email-row .email-label input,
.settings .content .section .email-row .email-label span {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 31px;
}

.settings .content .section .email-row .email-label input {
  border: solid grey;
  border-width: 0 0 1px;
  background: none;
  width: 100%;
  padding-top: 0;
}

.settings .content .section .email-row .email-label input:focus {
  outline: none !important;
}

.settings .content .section .email-notification button {
  border: 1px solid #d9d9d9;
  background: #fff;
  font-family: SF Pro Text Regular;
  font-size: 12.01px;
  line-height: 23.01px;
  color: #767676;
  width: 36px;
  height: 28px;
}

.settings .content .section .email-notification .on {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.settings .content .section .email-notification .off {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.settings .content .section .email-notification .active {
  background: #3f8540 !important;
  color: #fff !important;
  border: 0 !important;
}

.settings .content .section .link span {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 31px;
  color: #3f8540;
  text-decoration: underline;
  cursor: pointer;
}

.deactive-modal {
  padding: 13px 90px;
}

.deactive-modal p {
  font-family: SF Pro Display Regular;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #111;
}

.deactive-modal .confirm-deactive {
  width: 150px;
  height: 45px;
  line-height: 18.5px;
  color: #fff;
  background: #ff3b30;
  border: 0;
  border-radius: 3px;
}

.deactive-modal .cancel-deactive,
.deactive-modal .confirm-deactive {
  font-family: SF Compact Display Regular;
  font-size: 15px;
  cursor: pointer;
}

.deactive-modal .cancel-deactive {
  line-height: 23px;
  color: #007aff;
}

.account-delete-modal {
  padding: 13px 36px;
}

.account-delete-modal p {
  font-family: SF Pro Display Regular;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #111;
}

.account-delete-modal .contract-email {
  width: 338px;
  height: 25px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  background: #fff;
  padding: 5px;
}

.account-delete-modal .confirm-account-delete {
  width: 150px;
  height: 45px;
  font-family: SF Compact Display Regular;
  font-size: 15px;
  line-height: 18.5px;
  color: #fff;
  background: #ff3b30;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
}

.account-delete-modal .cancel-account-delete {
  font-family: SF Compact Display Regular;
  font-size: 15px;
  line-height: 23px;
  color: #007aff;
  cursor: pointer;
}

@media only screen and (max-width: 482px) {
  .settings .email-row .email-label {
    width: 100% !important;
  }

  .settings .email-row .email-btn {
    padding-top: 60px;
    text-align: left;
  }

  .ReactModalPortal .ReactModal__Content {
    left: 0 !important;
    width: calc(100% - 40px) !important;
    margin: 0 20px;
  }

  .ReactModalPortal .ReactModal__Content .account-delete-modal,
  .ReactModalPortal .ReactModal__Content .deactive-modal {
    padding: 13px 20px !important;
  }

  .ReactModalPortal .ReactModal__Content .account-delete-modal .contract-email {
    width: calc(100% - 40px) !important;
  }
}

.welcome {
  min-height: 650px;
}

.welcome span.title {
  font-family: SF Pro Display Bold;
  font-size: 42px;
  letter-spacing: -0.01em;
}

.welcome p.desc {
  font-family: Calluna;
  font-size: 21px;
  line-height: 31px;
}

.welcome p.desc a {
  color: #038930;
  cursor: pointer;
  text-decoration: none;
}

.welcome .btn-app-store {
  width: 150px;
  height: 50px;
  cursor: pointer;
  margin-top: 15px;
}

.terms-of-service .title {
  padding-bottom: 35px;
  border: solid #d6d6d6;
  border-width: 0 0 1px;
}

.terms-of-service .title span {
  font-family: SF Pro Display Medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #757575;
}

.terms-of-service .content p {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 24px;
}

.terms-of-service .content p span {
  font-family: SF Pro Text Bold !important;
}

@font-face {
  font-family: SF Pro Display Regular;
  src: url(/static/media/SF-Pro-Display-Regular.ae18f767.otf) format("opentype");
}

@font-face {
  font-family: SF Pro Display Medium;
  src: url(/static/media/SF-Pro-Display-Medium.86495c77.otf) format("opentype");
}

@font-face {
  font-family: SF Pro Display Bold;
  src: url(/static/media/SF-Pro-Display-Bold.2e684c71.otf) format("opentype");
}

@font-face {
  font-family: Calluna Regular;
  src: url(/static/media/Calluna-Regular.c311a16e.otf) format("opentype");
}

@font-face {
  font-family: SF Compact Text SemiBold;
  src: url(/static/media/SFCompactText-Semibold.103532c4.otf) format("opentype");
}

@font-face {
  font-family: SF Pro Text Regular;
  src: url(/static/media/SF-Pro-Text-Regular.889036a2.otf) format("opentype");
}

@font-face {
  font-family: Saol Display Bold;
  src: url(/static/media/Saol-Display-Bold.dfe90444.otf) format("opentype");
}

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px;
  }
}

.btn-icon {
  cursor: pointer;
}

.btn-icon-active,
.btn-icon:hover {
  fill: #000 !important;
}

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px;
}

.loading {
  animation: loading-spin 1s linear infinite;
  height: 10vmin;
  width: 24px;
  height: 24px;
}

@keyframes loading-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.hide {
  display: none;
}

.privacy-policy .title {
  padding-bottom: 35px;
  border: solid #d6d6d6;
  border-width: 0 0 1px;
}

.privacy-policy .title span {
  font-family: SF Pro Display Medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #757575;
}

.privacy-policy .content p {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 24px;
}

.privacy-policy .content p span {
  font-family: SF Pro Text Bold !important;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin 20s linear infinite;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}
/*# sourceMappingURL=main.6f221fa4.chunk.css.map */

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@media only screen and (max-width: 300px) {
  .sign-button {
    width: 100% !important;
    height: auto !important; } }

.sign-button {
  background: white;
  width: 218px;
  height: 43px;
  border-radius: 3px;
  -webkit-filter: drop-shadow(0px 2px 1px #ede9e3);
          filter: drop-shadow(0px 2px 1px #ede9e3); }
  .sign-button .icon {
    position: absolute; }
    .sign-button .icon img {
      position: absolute;
      left: 18px;
      width: 22px; }
  .sign-button .name {
    padding-left: 51px;
    padding-top: 10px;
    padding-bottom: 10px; }
    .sign-button .name span {
      font-family: 'SF Compact Display Regular' !important;
      font-size: 15px !important;
      line-height: 18.5px !important;
      color: #757575 !important; }
  .sign-button:hover {
    cursor: pointer; }
    .sign-button:hover span {
      color: #959595 !important; }
  .sign-button:active {
    cursor: pointer; }
    .sign-button:active span {
      color: #525252 !important; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

body {
  background: #f3ecdf; }

.sign-page .sign-container .logo {
  padding-top: 57px;
  padding-bottom: 32px;
  width: 100%; }
  .sign-page .sign-container .logo img {
    width: 49px;
    height: 64px; }

.sign-page .sign-container .main-block {
  border: 1px solid #e7e3db;
  border-radius: 4px;
  background: #fbf7f0;
  padding: 31px 40px 40px 40px; }
  .sign-page .sign-container .main-block .title {
    font-family: 'SF Compact Text Semibold';
    font-size: 14px;
    letter-spacing: 0.025em;
    color: #ff4500;
    text-transform: uppercase; }
  .sign-page .sign-container .main-block p {
    font-family: 'Calluna Regular';
    font-size: 18px;
    line-height: 30px;
    color: #111111;
    margin-top: 11px; }
    .sign-page .sign-container .main-block p span {
      font-family: 'Calluna Bold'; }
  .sign-page .sign-container .main-block .signin-action span {
    font-family: 'SF Pro Display Regular';
    font-size: 14px;
    line-height: 24px;
    color: #897655; }
    .sign-page .sign-container .main-block .signin-action span a {
      color: #038930;
      text-decoration: none; }
  .sign-page .sign-container .main-block .signin-form input {
    width: 219px;
    height: 48px;
    border: 1px solid #6f6d6a;
    background: none;
    border-width: 0 0 1px 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    text-align: center;
    font-family: 'SF Compact Display Regular'; }
    .sign-page .sign-container .main-block .signin-form input::-webkit-input-placeholder {
      font-family: 'SF Compact Display Regular';
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      color: #757575; }
    .sign-page .sign-container .main-block .signin-form input::placeholder {
      font-family: 'SF Compact Display Regular';
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      color: #757575; }
    .sign-page .sign-container .main-block .signin-form input:focus {
      outline: none !important; }
  .sign-page .sign-container .main-block .signin-form input.invalid {
    border-color: red !important; }
    .sign-page .sign-container .main-block .signin-form input.invalid::-webkit-input-placeholder {
      color: red; }
    .sign-page .sign-container .main-block .signin-form input.invalid::placeholder {
      color: red; }
  .sign-page .sign-container .main-block .signin-form .form-action {
    font-family: 'SF Compact Display Regular';
    font-size: 15px;
    line-height: 18.5px;
    color: white;
    background: #3c863c;
    border: 0px;
    border-radius: 3px;
    width: 150px;
    text-align: center;
    padding: 14px 0px; }

.sign-page .sign-container .apple {
  padding-top: 32px; }
  .sign-page .sign-container .apple img {
    width: 32px; }
  .sign-page .sign-container .apple span {
    font-family: 'SF Pro Text Regular';
    font-size: 16px;
    line-height: 30px;
    color: #aa9c84; }

.sign-page .sign-container .last-footer {
  padding-top: 10px; }
  .sign-page .sign-container .last-footer span {
    font-family: 'SF Pro Display Regular';
    font-size: 14px;
    line-height: 18.5px;
    letter-spacing: 0.01em;
    color: #aa9c84; }
    .sign-page .sign-container .last-footer span a {
      text-decoration: underline;
      color: #aa9c84; }

.sign-page .sign-container .email-verify {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid transparent;
  border-radius: .25rem;
  border-color: #c3e6cb;
  padding: 10px 20px; }

.sign-page .sign-container .hidden {
  display: none; }

.sign-page .sign-back {
  position: absolute;
  top: 17px;
  left: 50px; }
  .sign-page .sign-back img {
    width: 16px;
    height: 30px; }

@media only screen and (max-width: 491px) {
  .sign-page .sign-container {
    padding: 0 23px 0 23px; }
    .sign-page .sign-container .main-block {
      padding: 31px 17px 40px 17px; } }

@media only screen and (min-width: 491px) {
  .sign-page .sign-container {
    width: 442px; } }

.sign-page .sign-container .apple {
  padding-top: 32px;
}

.sign-page .sign-container .apple img {
  width: 150px;
  height: 50px;
}

.sign-page .sign-container .apple span {
  font-family: SF Pro Text Regular;
  font-size: 16px;
  line-height: 30px;
  color: #aa9c84;
}

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.header-back {
  width: 100%;
  height: 82px;
  border: 1px solid #d6d6d6;
  border-width: 0 0 1px 0;
  background: #f3ecdf; }
  .header-back .logo {
    padding-top: 24px;
    float: left;
    width: 30px; }
    .header-back .logo img {
      width: 27px;
      height: 35px; }
  .header-back .header-action {
    padding-top: 23px;
    width: 100%; }
    .header-back .header-action .actions {
      float: right;
      margin-left: 36px; }
      .header-back .header-action .actions svg {
        margin-top: 4px;
        float: left; }

@keyframes search-ani-show {
  from {
    width: 0px; }
  to {
    width: 220px; } }
      .header-back .header-action .actions input {
        width: 220px;
        height: 37px;
        border: 0;
        background: #fbf7f0;
        margin-left: 11px;
        padding: 0 12px;
        animation-name: search-ani-show;
        animation-duration: 1s; }
        .header-back .header-action .actions input::-webkit-input-placeholder {
          font-family: 'SF Pro Display Medium';
          font-size: 14px;
          line-height: 36px; }
        .header-back .header-action .actions input::placeholder {
          font-family: 'SF Pro Display Medium';
          font-size: 14px;
          line-height: 36px; }
      .header-back .header-action .actions .hide {
        display: none; }
      .header-back .header-action .actions .sub-menu {
        position: absolute; }

@keyframes user-menu-ani-show {
  from {
    height: 0px; }
  to {
    height: 108px; } }
        .header-back .header-action .actions .sub-menu .user-menu {
          position: absolute;
          border: 1px solid #d6d6d6;
          border-radius: 5px;
          background: white;
          list-style: none;
          top: 40px;
          right: -32px;
          padding: 20px 0;
          text-align: left;
          width: 232px;
          margin: 0;
          animation-name: user-menu-ani-show;
          animation-duration: 0.5s; }
          .header-back .header-action .actions .sub-menu .user-menu li {
            padding: 0 20px;
            cursor: pointer; }
            .header-back .header-action .actions .sub-menu .user-menu li a, .header-back .header-action .actions .sub-menu .user-menu li div {
              font-family: 'SF Pro Display Medium';
              font-size: 14px;
              line-height: 36px;
              color: #757575;
              text-decoration: none;
              padding: 0; }
            .header-back .header-action .actions .sub-menu .user-menu li:hover {
              background: #e4e4e4; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@media only screen and (max-width: 1052px) {
  .page-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .page-container {
    width: 700px; } }

.tertius-main-page {
  width: 100%;
  background: #fbf7f0; }
  .tertius-main-page .page-container {
    padding-top: 66px;
    padding-bottom: 66px; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.page-search {
  min-height: 650px; }
  .page-search .content span {
    font-family: 'SF Pro Display Medium';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.075em;
    color: #757575;
    text-transform: uppercase; }
  .page-search .content input {
    margin: 20px 0;
    border: 1px solid #d6d6d6;
    border-width: 0 0 1px 0;
    background: none;
    font-family: 'SF Pro Display Regular';
    font-size: 40px;
    width: -webkit-fill-available;
    width: 100%; }
    .page-search .content input:focus {
      outline: none !important; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.page-filter {
  min-height: 650px; }
  .page-filter .post-line {
    width: 100%;
    height: 1px;
    background: #d6d6d6; }
  .page-filter .filterMenu span {
    cursor: pointer; }
  .page-filter .content .filterMenu {
    float: right; }
  .page-filter .content span {
    font-family: "SF Pro Display Medium";
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.075em;
    color: #757575; }
  .page-filter .content .tags {
    width: 100%;
    padding: 20px 0 28px 0;
    display: inline-block; }
    .page-filter .content .tags .tag {
      float: left;
      background: white;
      border: 1px solid #d6d6d6;
      border-radius: 3px;
      padding: 6px 18px 5px 19px;
      font-family: "SF Pro Display Medium";
      font-size: 14px;
      line-height: 24px;
      color: #757575;
      margin-right: 9px;
      margin-bottom: 9px;
      cursor: pointer; }
      .page-filter .content .tags .tag:hover {
        background: #f1f1f1; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.post-list {
  min-height: 650px; }
  .post-list .postlist .action {
    padding: 33px 0 0 0; }
  .post-list .postlist .filter-header span.fh-title {
    text-transform: uppercase;
    font-family: 'SF Pro Display Medium';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.075em;
    color: #757575; }
  .post-list .postlist .filter-header .tag {
    background: white;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    padding: 6px 18px 5px 19px;
    font-family: 'SF Pro Display Medium';
    font-size: 14px;
    line-height: 24px;
    color: #757575;
    display: inline-block; }
  .post-list .postlist .filter-header .filter-line {
    width: 100%;
    height: 1px;
    background: #d6d6d6; }
  .post-list .postlist .filter-header input {
    font-family: 'SF Pro Display Regular';
    font-size: 36px;
    line-height: 24px;
    color: #111111;
    border: 0px;
    padding: 0 7px;
    width: 100%;
    background: none; }
    .post-list .postlist .filter-header input:focus {
      outline: none !important; }
  .post-list .postlist .filter-header span.filter-content {
    font-family: 'SF Pro Display Regular';
    font-size: 36px;
    line-height: 24px;
    color: #111111; }

.post {
  min-height: 650px; }
  .post .citations {
    width: 100%; }
    .post .citations .citation {
      padding-left: 24px;
      padding-bottom: 2px;
      cursor: pointer;
      min-height: 25px; }
      .post .citations .citation .icon {
        position: absolute; }
        .post .citations .citation .icon svg {
          position: absolute;
          top: 2px;
          left: -25px; }
      .post .citations .citation span {
        font-family: 'SF Pro Display Regular';
        font-size: 16px;
        line-height: 24.01px;
        letter-spacing: 0.025em;
        color: #6d6d6d; }
  .post .post-setting {
    float: right;
    width: 30px; }
    .post .post-setting .sub-menu {
      position: absolute; }

@keyframes post-setting-menu-ani-show {
  from {
    height: 0px; }
  to {
    height: 216px; } }
      .post .post-setting .sub-menu .setting-menu {
        position: absolute;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        background: white;
        list-style: none;
        top: 15px;
        right: -32px;
        padding: 20px 0;
        text-align: left;
        width: 232px;
        margin: 0;
        animation-name: post-setting-menu-ani-show;
        animation-duration: 0.5s; }
        .post .post-setting .sub-menu .setting-menu li {
          padding: 0 20px;
          cursor: pointer; }
          .post .post-setting .sub-menu .setting-menu li a, .post .post-setting .sub-menu .setting-menu li div {
            font-family: 'SF Pro Display Medium';
            font-size: 14px;
            line-height: 36px;
            color: #757575;
            text-decoration: none;
            padding: 0; }
          .post .post-setting .sub-menu .setting-menu li .trash {
            color: #ff3b30; }
          .post .post-setting .sub-menu .setting-menu li:hover {
            background: #e4e4e4; }
  .post .content span.post-title {
    font-family: 'SF Pro Display Bold';
    font-size: 42px;
    letter-spacing: -0.01em; }
  .post .content p.post-content {
    font-family: 'Calluna Regular';
    font-size: 21px;
    line-height: 31px;
    white-space: pre-line;
    margin: 0; }
    .post .content p.post-content p {
      margin: 0 !important; }
  .post .content .post-line {
    width: 100%;
    height: 1px;
    background: #d6d6d6; }
  .post .content .tag-list {
    width: 100%;
    padding: 40px 0 28px 0;
    display: inline-block; }
    .post .content .tag-list .tag {
      float: left;
      background: white;
      border: 1px solid #d6d6d6;
      border-radius: 3px;
      padding: 6px 18px 5px 19px;
      font-family: 'SF Pro Display Medium';
      font-size: 14px;
      line-height: 24px;
      color: #757575;
      margin-right: 9px;
      margin-bottom: 9px;
      cursor: pointer; }
  .post .content .comment .comment-head {
    padding-left: 26px;
    position: absolute; }
    .post .content .comment .comment-head svg {
      position: absolute;
      top: 2px;
      left: 0px; }
    .post .content .comment .comment-head span {
      font-family: 'SF Pro Text Regular';
      font-size: 16px;
      line-height: 24px;
      color: #111111; }
  .post .content .comment p {
    font-family: 'SF Pro Text Regular';
    font-size: 16px;
    line-height: 31px; }

.edit-title-excerpt {
  min-height: 650px; }
  .edit-title-excerpt .content .post-title {
    width: 100%; }
    .edit-title-excerpt .content .post-title textarea {
      width: -webkit-fill-available;
      width: 100%;
      resize: none;
      padding: 0;
      border: 0px;
      background: none;
      font-family: 'SF Pro Display Bold';
      font-size: 42px;
      letter-spacing: -0.01em;
      color: #111111;
      min-height: 50px !important; }
      .edit-title-excerpt .content .post-title textarea:focus {
        outline: none !important; }
  .edit-title-excerpt .content .post-excerpt {
    padding-top: 5px;
    width: 100%; }
    .edit-title-excerpt .content .post-excerpt p.excerpt-para {
      white-space: pre-line; }
      .edit-title-excerpt .content .post-excerpt p.excerpt-para .medium-editor-element {
        width: -webkit-fill-available !important;
        width: 100% !important;
        resize: none !important;
        padding: 0 !important;
        border: 0px !important;
        background: none !important;
        font-family: 'Calluna Regular' !important;
        font-size: 21px !important;
        line-height: 31px !important;
        color: #111111 !important;
        outline: none !important; }
        .edit-title-excerpt .content .post-excerpt p.excerpt-para .medium-editor-element:focus {
          outline: none !important; }
        .edit-title-excerpt .content .post-excerpt p.excerpt-para .medium-editor-element p {
          margin: 0; }
  .edit-title-excerpt .content .post-actions {
    position: relative; }
    .edit-title-excerpt .content .post-actions .buttons {
      width: 210px;
      position: absolute;
      top: -130px;
      right: -100px; }

.edit-tags {
  min-height: 650px; }
  .edit-tags .content .tags {
    width: 100%;
    display: inline-block; }
    .edit-tags .content .tags .tag {
      float: left;
      background: white;
      border: 1px solid #d6d6d6;
      border-radius: 3px;
      padding: 6px 15px 5px 20px;
      margin-right: 10px;
      margin-bottom: 9px; }
      .edit-tags .content .tags .tag span {
        float: left;
        font-family: 'SF Pro Display Medium';
        font-size: 14px;
        line-height: 24px;
        color: #757575; }
      .edit-tags .content .tags .tag .btn-close {
        float: left;
        padding-left: 3px;
        padding-top: 3px; }
    .edit-tags .content .tags input {
      border: 0;
      background: none;
      height: 37px;
      padding-left: 5px;
      padding-top: 0; }
      .edit-tags .content .tags input:placeholder {
        font-family: 'SF Pro Display Medium';
        font-size: 14px;
        line-height: 24px;
        color: #757575; }
      .edit-tags .content .tags input:focus {
        outline: none !important; }
    .edit-tags .content .tags .input_tag {
      font-size: 14px !important;
      line-height: 24px !important;
      color: #757575 !important;
      font-family: 'SF Pro Display Medium' !important; }
  .edit-tags .content .actions {
    padding-top: 30px; }

.edit-comment {
  min-height: 650px; }
  .edit-comment .content textarea {
    width: -webkit-fill-available;
    width: 100%;
    resize: none;
    padding: 0;
    border: 0px;
    background: none;
    min-height: 220px;
    max-height: 400px;
    overflow: scroll;
    font-family: 'SF Pro Text Regular';
    font-size: 16px;
    line-height: 31px; }
    .edit-comment .content textarea::-webkit-scrollbar {
      width: 0px; }
    .edit-comment .content textarea:focus {
      outline: none !important; }
  .edit-comment .content .actions {
    padding-top: 40px; }

.edit-citation {
  padding-top: 40px !important;
  min-height: 650px; }
  .edit-citation .content .citation {
    width: 100%; }
    .edit-citation .content .citation input {
      padding: 12px 7px 0px 7px;
      border: 1px solid #d6d6d6;
      border-width: 0 0 1px 0;
      font-family: 'SF Pro Display Regular';
      font-size: 16px;
      line-height: 46px;
      color: #111111;
      background: none;
      width: -webkit-fill-available;
      width: 100%; }
      .edit-citation .content .citation input:focus {
        outline: none !important; }
  .edit-citation .content .actions {
    padding-top: 40px; }

.fr-buttons, .fr-btn, .fr-toolbar {
  background: black !important; }

.fr-btn {
  color: white !important; }

.fr-popup {
  border-color: black !important; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  color: black !important; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.settings .title {
  padding-bottom: 35px;
  border: 1px solid #d6d6d6;
  border-width: 0 0 1px 0; }
  .settings .title span {
    font-family: 'SF Pro Display Medium';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #757575; }

.settings .content .section {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-width: 0 0 1px 0;
  padding: 39px 0 32px 0; }
  .settings .content .section .section-title span {
    font-family: 'SF Pro Display Bold';
    font-size: 18px;
    letter-spacing: -0.01em; }
  .settings .content .section p {
    font-family: 'SF Pro Text Regular';
    font-size: 16px;
    line-height: 31px; }
  .settings .content .section .email-row .email-label {
    float: left;
    padding-top: 5px;
    width: calc(100% - 150px); }
    .settings .content .section .email-row .email-label span {
      font-family: 'SF Pro Text Regular';
      font-size: 16px;
      line-height: 31px; }
    .settings .content .section .email-row .email-label input {
      font-family: 'SF Pro Text Regular';
      font-size: 16px;
      line-height: 31px;
      border: 1px solid gray;
      border-width: 0 0 1px 0;
      background: none;
      width: 100%;
      padding-top: 0; }
      .settings .content .section .email-row .email-label input:focus {
        outline: none !important; }
  .settings .content .section .email-notification button {
    border: 1px solid #d9d9d9;
    background: white;
    font-family: 'SF Pro Text Regular';
    font-size: 12.01px;
    line-height: 23.01px;
    color: #767676;
    width: 36px;
    height: 28px; }
  .settings .content .section .email-notification .on {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .settings .content .section .email-notification .off {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .settings .content .section .email-notification .active {
    background: #3f8540 !important;
    color: white !important;
    border: 0 !important; }
  .settings .content .section .link span {
    font-family: 'SF Pro Text Regular';
    font-size: 16px;
    line-height: 31px;
    color: #3f8540;
    text-decoration: underline;
    cursor: pointer; }

.deactive-modal {
  padding: 13px 90px; }
  .deactive-modal p {
    font-family: 'SF Pro Display Regular';
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #111111; }
  .deactive-modal .confirm-deactive {
    width: 150px;
    height: 45px;
    font-family: 'SF Compact Display Regular';
    font-size: 15px;
    line-height: 18.5px;
    color: white;
    background: #ff3b30;
    border: 0;
    border-radius: 3px;
    cursor: pointer; }
  .deactive-modal .cancel-deactive {
    font-family: 'SF Compact Display Regular';
    font-size: 15px;
    line-height: 23px;
    color: #007aff;
    cursor: pointer; }

.account-delete-modal {
  padding: 13px 36px; }
  .account-delete-modal p {
    font-family: 'SF Pro Display Regular';
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #111111; }
  .account-delete-modal .contract-email {
    width: 338px;
    height: 25px;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    background: white;
    padding: 5px; }
  .account-delete-modal .confirm-account-delete {
    width: 150px;
    height: 45px;
    font-family: 'SF Compact Display Regular';
    font-size: 15px;
    line-height: 18.5px;
    color: white;
    background: #ff3b30;
    border: 0;
    border-radius: 3px;
    cursor: pointer; }
  .account-delete-modal .cancel-account-delete {
    font-family: 'SF Compact Display Regular';
    font-size: 15px;
    line-height: 23px;
    color: #007aff;
    cursor: pointer; }

@media only screen and (max-width: 482px) {
  .settings .email-row .email-label {
    width: 100% !important; }
  .settings .email-row .email-btn {
    padding-top: 60px;
    text-align: left; }
  .ReactModalPortal .ReactModal__Content {
    left: 0 !important;
    width: calc(100% - 40px) !important;
    margin: 0 20px; }
    .ReactModalPortal .ReactModal__Content .deactive-modal {
      padding: 13px 20px !important; }
    .ReactModalPortal .ReactModal__Content .account-delete-modal {
      padding: 13px 20px !important; }
      .ReactModalPortal .ReactModal__Content .account-delete-modal .contract-email {
        width: calc(100% - 40px) !important; } }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.terms-of-service .title {
  padding-bottom: 35px;
  border: 1px solid #d6d6d6;
  border-width: 0 0 1px 0; }
  .terms-of-service .title span {
    font-family: 'SF Pro Display Medium';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #757575; }

.terms-of-service .content p {
  font-family: 'SF Pro Text Regular';
  font-size: 16px;
  line-height: 24px; }
  .terms-of-service .content p span {
    font-family: 'SF Pro Text Bold' !important; }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.privacy-policy .title {
  padding-bottom: 35px;
  border: 1px solid #d6d6d6;
  border-width: 0 0 1px 0; }
  .privacy-policy .title span {
    font-family: 'SF Pro Display Medium';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #757575; }

.privacy-policy .content p {
  font-family: 'SF Pro Text Regular';
  font-size: 16px;
  line-height: 24px; }
  .privacy-policy .content p span {
    font-family: 'SF Pro Text Bold' !important; }

.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/assets/fonts/SF-Pro-Display-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(/assets/fonts/SF-Pro-Display-Medium.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/assets/fonts/SF-Pro-Display-Bold.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Regular";
  src: url(/assets/fonts/Calluna-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Calluna Bold";
  src: url(/assets/fonts/Calluna-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Text SemiBold";
  src: url(/assets/fonts/SFCompactText-Semibold.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Regular";
  src: url(/assets/fonts/SF-Pro-Text-Regular.otf) format("opentype"); }

@font-face {
  font-family: "SF Pro Text Bold";
  src: url(/assets/fonts/SF-Pro-Text-Bold.otf) format("opentype"); }

@font-face {
  font-family: "SF Compact Display Regular";
  src: url(/assets/fonts/SFCompactDisplay-Regular.otf) format("opentype"); }

@media only screen and (max-width: 1052px) {
  .content-container {
    width: calc(100%-20px);
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 1052px) {
  .content-container {
    width: 1032px; } }

.btn-icon {
  cursor: pointer; }

.btn-icon:hover {
  fill: black !important; }

.btn-icon-active {
  fill: black !important; }

.loading-container {
  padding-top: 100px;
  padding-bottom: 100px; }

.loading {
  animation: loading-spin infinite 1s linear;
  height: 10vmin;
  width: 24px;
  height: 24px; }

@keyframes loading-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.post-item .post-item-content {
  width: 100%; }
  .post-item .post-item-content .citation {
    padding-left: 24px;
    padding-bottom: 2px;
    min-height: 25px; }
    .post-item .post-item-content .citation .icon {
      position: absolute; }
      .post-item .post-item-content .citation .icon svg {
        position: absolute;
        top: 2px;
        left: -25px; }
    .post-item .post-item-content .citation span {
      font-family: 'SF Pro Display Regular';
      font-size: 16px;
      line-height: 24.01px;
      letter-spacing: 0.025em;
      color: #6d6d6d; }
  .post-item .post-item-content span.post-title {
    font-family: 'SF Pro Display Bold';
    font-size: 42px;
    letter-spacing: -0.01em;
    cursor: pointer;
    color: #111111; }
    .post-item .post-item-content span.post-title:hover {
      color: #666666; }
    .post-item .post-item-content span.post-title:active {
      color: #000000; }
  .post-item .post-item-content p.post-content {
    font-family: 'Calluna Regular';
    font-size: 21px;
    line-height: 31px;
    height: 90px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    .post-item .post-item-content p.post-content :first-child {
      margin-top: 0; }

.post-item .post-line {
  width: 100%;
  height: 1px;
  background: #d6d6d6; }

.welcome {
  min-height: 650px;
}

.welcome span.title {
  font-family: SF Pro Display Bold;
  font-size: 42px;
  letter-spacing: -0.01em;
}

.welcome p.desc {
  font-family: Calluna;
  font-size: 21px;
  line-height: 31px;
}

.welcome p.desc a {
  color: #038930;
  cursor: pointer;
  text-decoration: none;
}

.welcome .btn-app-store {
  width: 150px;
  height: 50px;
  cursor: pointer;
  margin-top: 15px;
}

