.welcome {
  min-height: 650px;
}

.welcome span.title {
  font-family: SF Pro Display Bold;
  font-size: 42px;
  letter-spacing: -0.01em;
}

.welcome p.desc {
  font-family: Calluna;
  font-size: 21px;
  line-height: 31px;
}

.welcome p.desc a {
  color: #038930;
  cursor: pointer;
  text-decoration: none;
}

.welcome .btn-app-store {
  width: 150px;
  height: 50px;
  cursor: pointer;
  margin-top: 15px;
}
