@import '../../../common.scss';

.post-list {
	min-height: $content-min-height;
	.postlist {
		.action {
			padding: 33px 0 0 0;
		}

		.filter-header {
			span.fh-title {
				text-transform: uppercase;
				font-family: 'SF Pro Display Medium';
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.075em;
				color: #757575;
			}

			.tag {
				background: white;
				border: 1px solid #d6d6d6;
				border-radius: 3px;
				padding: 6px 18px 5px 19px;
				font-family: 'SF Pro Display Medium';
				font-size: 14px;
				line-height: 24px;
				color: #757575;
				display: inline-block;
			}

			.filter-line {
				width: 100%;
				height: 1px;
				background: #d6d6d6;
			}

			input {
				font-family: 'SF Pro Display Regular';
				font-size: 36px;
				line-height: 24px;
				color: #111111;
				border: 0px;
				padding: 0 7px;
				width: 100%;
				background: none;

				&:focus {
					outline: none !important;
				}
			}

			span.filter-content {
				font-family: 'SF Pro Display Regular';
				font-size: 36px;
				line-height: 24px;
				color: #111111;
			}
		}
	}
}

.post {
	min-height: $content-min-height;
	.citations {
		width: 100%;

		.citation {
			padding-left: 24px;
			padding-bottom: 2px;
			cursor: pointer;
			min-height: 25px;

			.icon {
				position: absolute;

				svg {
					position: absolute;
					top: 2px;
					left: -25px;
				}
			}

			span {
				font-family: 'SF Pro Display Regular';
				font-size: 16px;
				line-height: 24.01px;
				letter-spacing: 0.025em;
				color: #6d6d6d;
			}
		}
	}

	.post-setting {
		float: right;
		width: 30px;

		.sub-menu {
			position: absolute;

			@keyframes post-setting-menu-ani-show {
			  from {height: 0px;}
			  to {height: 216px;}
			}

			.setting-menu{
				position: absolute;
				border: 1px solid #d6d6d6;
				border-radius: 5px;
				background: white;
				list-style: none;
				top: 15px;
				right: -32px;
				padding: 20px 0;
				text-align: left;
				width: 232px;
				margin: 0;
				animation-name: post-setting-menu-ani-show;
				animation-duration: 0.5s;

				li {
					padding: 0 20px;
					cursor: pointer;
					
					a, div {
						font-family: 'SF Pro Display Medium';
						font-size: 14px;
						line-height: 36px;
						color: #757575;
						text-decoration: none;
						padding: 0;
					}
					.trash {
						color: #ff3b30;
					}

					&:hover {
						background: #e4e4e4;
					}
				}
			}
		}
	}

	.content {
		span.post-title {
			font-family: 'SF Pro Display Bold';
			font-size: 42px;
			letter-spacing: -0.01em;
		}

		p.post-content {
			font-family: 'Calluna Regular';
			font-size: 21px;
			line-height: 31px;
			white-space: pre-line;
			margin: 0;

			p {
				margin: 0 !important;
			}
		}

		.post-line {
			width: 100%;
			height: 1px;
			background: #d6d6d6;
		}

		.tag-list {
			width: 100%;
			padding: 40px 0 28px 0;
			display: inline-block;

			.tag {
				float: left;
				background: white;
				border: 1px solid #d6d6d6;
				border-radius: 3px;
				padding: 6px 18px 5px 19px;
				font-family: 'SF Pro Display Medium';
				font-size: 14px;
				line-height: 24px;
				color: #757575;
				margin-right: 9px;
				margin-bottom: 9px;
				cursor: pointer;
			}
		}

		.comment {

			.comment-head {
				padding-left: 26px;
				position: absolute;

				svg {
					position: absolute;
					top: 2px;
					left: 0px;
				}

				span {
					font-family: 'SF Pro Text Regular';
					font-size: 16px;
					line-height: 24px;
					color: #111111;
				}
			}

			p {
				font-family: 'SF Pro Text Regular';
				font-size: 16px;
				line-height: 31px;
			}
		}
	}
}

.edit-title-excerpt {
	min-height: $content-min-height;
	.content {
		.post-title {
			width: 100%;

			textarea {
				width: -webkit-fill-available;
				width: 100%;
				resize: none;
				padding: 0;
			    border: 0px;
			    background: none;
			    font-family: 'SF Pro Display Bold';
			    font-size: 42px;
			    letter-spacing: -0.01em;
				color: #111111;
				min-height: 50px !important;

			    &:focus {
					outline: none !important;
				}
			}
		}

		.post-excerpt {
			padding-top: 5px;
			width: 100%;

			p.excerpt-para{
				white-space: pre-line;
				
				.medium-editor-element {
					width: -webkit-fill-available !important;
					width: 100% !important;
					resize: none !important;
					padding: 0 !important;
					border: 0px !important;
					background: none !important;
					font-family: 'Calluna Regular' !important;
					font-size: 21px !important;
					line-height: 31px !important;
					color: #111111 !important;
					outline: none !important;

					&:focus {
						outline: none !important;
					}

					p{
						margin: 0;
					}
				}
			}
		}

		.post-actions {
			position: relative;
			.buttons {
				width: 210px;
				position: absolute;
				top: -130px;
				right: -100px;
			}
		}
	}
}

.edit-tags {
	min-height: $content-min-height;
	.content {
		.tags {
			width: 100%;
			display: inline-block;

			.tag {
				float: left;
				background: white;
				border: 1px solid #d6d6d6;
				border-radius: 3px;
				padding: 6px 15px 5px 20px;
				margin-right: 10px;
				margin-bottom: 9px;

				span {
					float: left;
					font-family: 'SF Pro Display Medium';
					font-size: 14px;
					line-height: 24px;
					color: #757575;
				}

				.btn-close {
					float: left;
					padding-left: 3px;
					padding-top: 3px;
				}
			}

			input {
				border: 0;
				background: none;
				height: 37px;
				padding-left: 5px;
				padding-top: 0;

				&:placeholder {
					font-family: 'SF Pro Display Medium';
					font-size: 14px;
					line-height: 24px;
					color: #757575;					
				}

				&:focus {
					outline: none !important;
				}
			}

			.input_tag {
				font-size: 14px !important;
				line-height: 24px !important;
				color: #757575 !important;
				font-family: 'SF Pro Display Medium' !important;
			}
		}

		.actions {
			padding-top: 30px;
		}
	}
}

.edit-comment {
	min-height: $content-min-height;
	.content {
		textarea {
			width: -webkit-fill-available;
			width: 100%;
			resize: none;
			padding: 0;
		    border: 0px;
		    background: none;
		    min-height: 220px;
		    max-height: 400px;
		    overflow: scroll;
		    font-family: 'SF Pro Text Regular';
		    font-size: 16px;
		    line-height: 31px;

		    &::-webkit-scrollbar {
			  width: 0px;
			}

			&:focus {
				outline: none !important;
			}
		}

		.actions {
			padding-top: 40px;
		}
	}
}

.edit-citation {
	padding-top: 40px !important;
	min-height: $content-min-height;
	.content {
		.citation {
			width: 100%;
			input {
				padding: 12px 7px 0px 7px;
				border: 1px solid #d6d6d6;
				border-width: 0 0 1px 0;
				font-family: 'SF Pro Display Regular';
				font-size: 16px;
				line-height: 46px;
				color: #111111;
				background: none;
				width: -webkit-fill-available;
				width: 100%;

				&:focus {
					outline: none !important;
				}
			}
		}

		.actions {
			padding-top: 40px;
		}
	}
}

.fr-buttons, .fr-btn, .fr-toolbar {
	background: black !important;
}

.fr-btn {
	color: white !important;
}

.fr-popup {
	border-color: black !important;
}

.fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
    color: black !important;
}